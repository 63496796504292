import { PAGES } from '@src/constants'
import { MeQuery } from '@src/graphql/generated/graphql'
import {
  BsBook,
  BsBuilding,
  BsCollectionPlay,
  BsColumnsGap,
  BsDisplay,
  BsEnvelope,
  BsFileCheck,
  BsFileEarmarkPpt,
  BsFileText,
  BsFolderPlus,
  BsGear,
  BsInbox,
  BsLayers,
  BsLayoutTextSidebarReverse,
  BsLock,
  BsPeople,
  BsPerson,
  BsPersonCheck,
} from 'react-icons/bs'

type role = MeQuery['me']['user']['role']

export const baseMenu = (role: role) =>
  [
    role.readDashboard && {
      href: '/dashboard',
      icon: <BsColumnsGap />,
      text: 'ダッシュボード',
    },
    role.readPhishingMail && {
      href: '/phishing',
      icon: <BsEnvelope />,
      text: 'フィッシングメール',
    },
    role.readPhishingMail && {
      href: '/phishing?isDeliveryContentsOnly=true',
      icon: <BsEnvelope />,
      text: 'コンテンツ配信',
    },
    role.readPhishingMail && {
      href: '/phishing/archives',
      icon: <BsInbox />,
      text: 'アーカイブ済',
    },
    role.readUser && {
      href: '/users/',
      icon: <BsPerson />,
      text: 'ユーザー',
    },
    role.readOrganization && {
      href: '/organizations',
      icon: <BsLayers />,
      text: '組織',
    },
    role.readGroup && {
      href: '/groups',
      icon: <BsPeople />,
      text: 'グループ',
    },
    role.readPosition && {
      href: '/positions',
      icon: <BsPersonCheck />,
      text: '役職',
    },
    role.readConfig && {
      href: '/settings',
      icon: <BsGear />,
      text: '設定',
    },
    role.readRole && {
      href: '/roles',
      icon: <BsPeople />,
      text: '権限',
    },
    role.readResearch && {
      href: PAGES.Researches.path,
      icon: <BsFileText />,
      text: 'アンケート管理',
    },
    role.readResearch && {
      href: PAGES.Questionnaires.path,
      icon: <BsFileText />,
      text: 'アンケートURL',
    },
    role.readPdf && {
      href: PAGES.Pdfs.path,
      icon: <BsFileText />,
      text: 'PDF',
    },
    role.readCompanyAttach && {
      href: PAGES.CustomAttaches.path,
      icon: <BsFileEarmarkPpt />,
      text: '添付ファイル',
    },
  ].filter((v): v is Exclude<typeof v, boolean> => typeof v !== 'boolean')

//管理者メニューを表示する権限があるか
export const isShowAdminMenu = (role: role) => {
  return [
    role.readPhishingSite,
    role.readFromDomain,
    role.readCompany,
    role.readMailTemplate,
    role.readAdminResearch,
  ].some((n) => n)
}

export const adminMenu = (role: role) => [
  {
    items: [
      {
        href: PAGES.Campaigns.path,
        icon: <BsEnvelope />,
        text: 'キャンペーン一覧',
      },
      role.readPhishingSite && {
        href: '/sites',
        icon: <BsDisplay />,
        text: 'フィッシングサイト',
      },
      role.readFromDomain && {
        href: '/mails',
        icon: <BsFolderPlus />,
        text: 'Fromドメイン',
      },
      role.readCompany && {
        href: '/companies',
        icon: <BsBuilding />,
        text: '企業一覧',
      },
      role.readMailTemplate && {
        href: '/templates',
        icon: <BsFileCheck />,
        text: 'テンプレート',
      },
      // role.readAdminResearch && {
      //   href: PAGES.AdminResearches.path,
      //   icon: <BsFileText />,
      //   text: '共通アンケート',
      // },
      role.readAdminResearch && {
        href: PAGES.Documents.path,
        icon: <BsFileEarmarkPpt />,
        text: 'ドキュメント',
      },
      {
        href: PAGES.Attaches.path,
        icon: <BsFileEarmarkPpt />,
        text: '添付ファイル',
      },
      {
        href: PAGES.UserLockLogs.path,
        icon: <BsLock />,
        text: 'ユーザーロックログ',
      },
    ].filter((v): v is Exclude<typeof v, boolean> => typeof v !== 'boolean'),
    title: '管理者メニュー',
  },
]

//コンテンツメニューを表示する権限があるか
export const isShowContentMenu = (role: role) => {
  return [
    role.readLearningMovie,
    role.readLearningComic,
    role.readLearningText,
  ].some((n) => n)
}

export const contentMenu = (role: role) => [
  {
    items: [
      role.readLearningMovie && {
        href: '/movies',
        icon: <BsCollectionPlay />,
        text: '動画',
      },
      role.readLearningComic && {
        href: '/comics',
        icon: <BsBook />,
        text: 'コミック',
      },
      role.readLearningText && {
        href: '/learn',
        icon: <BsLayoutTextSidebarReverse />,
        text: 'ラーニング',
      },
    ].filter((v): v is Exclude<typeof v, boolean> => typeof v !== 'boolean'),
    title: 'コンテンツ管理',
  },
]
