import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import 'moment/locale/ja'

import {
  Box,
  Center,
  Flex,
  Grid,
  Heading,
  HStack,
  Text,
  theme,
} from '@chakra-ui/react'
import { Global } from '@emotion/react'
import { CsbPrimaryBox, CsbSpinner } from '@src/components'
import { CsbHint } from '@src/components/CsbHint/CsbHint'
import { useDashboardHooksResult } from '@src/components/pages/dashboard/hooks'
import { Base } from '@src/layouts'
import { Dashboard as DashboardModel } from '@src/models/Dashboard'
import moment from 'moment'
import { memo } from 'react'
import { DateRangePicker } from 'react-dates'

const Test = ({
  current,
  hint,
  title,
  total,
}: Partial<{
  current: string
  hint?: string
  title: string
  total: string
}> = {}) => {
  return (
    <CsbPrimaryBox rounded={'md'}>
      <Flex
        align={'center'}
        direction={'column'}
        justify={'center'}
        pb={9}
        pt={22}
      >
        {title && (
          <HStack alignItems={'center'}>
            <Text color={'gray.500'} fontSize={'sm'} fontWeight={'bold'}>
              {title}
            </Text>
            {hint && <CsbHint>{hint}</CsbHint>}
          </HStack>
        )}
        {current && (
          <Text fontSize={'3xl'} fontWeight={'bold'} mt={4}>
            {current}
          </Text>
        )}
        {total && (
          <Text color={'gray.500'} fontSize={'sm'} fontWeight={'bold'}>
            {total}
          </Text>
        )}
      </Flex>
    </CsbPrimaryBox>
  )
}

//ダッシュボードページのcomponent
export const Dashboard = memo(
  ({ dateRangePicker, useDashboardResult }: useDashboardHooksResult) => {
    return (
      <Base
        left={<Heading>ダッシュボード</Heading>}
        right={
          <>
            <Global
              styles={{
                '.CalendarDay__selected_span': {
                  backgroundColor: theme.colors.teal['300'],
                },
                '.DayPickerKeyboardShortcuts_show__bottomRight': {
                  display: 'none',
                },
              }}
            />
            <DateRangePicker
              endDate={dateRangePicker.endDate}
              endDateId="endDateId"
              focusedInput={dateRangePicker.focusedInput}
              isOutsideRange={() => false}
              startDate={dateRangePicker.startDate}
              startDateId="startDateId"
              onDatesChange={(selectedDates) => {
                dateRangePicker.setStartDate(
                  selectedDates.startDate ?? moment(new Date())
                )
                dateRangePicker.setEndDate(
                  selectedDates.endDate ?? moment(new Date())
                )
              }}
              onFocusChange={dateRangePicker.setFocusedInput}
            />
          </>
        }
      >
        <Box mt={70}>
          <DashboardModel
            {...useDashboardResult}
            loadingFallback={
              <Grid
                columnGap={12}
                rowGap={6}
                templateColumns={'repeat(auto-fit,minmax(200px,1fr))'}
              >
                {Array.from(Array(7), (_, i) => i).map((i) => (
                  <Box bg={'gray.50'} borderWidth={1} key={i}>
                    <Center h={140}>
                      <CsbSpinner />
                    </Center>
                  </Box>
                ))}
              </Grid>
            }
          >
            {({ dashboard }) => (
              <Grid
                columnGap={12}
                rowGap={6}
                templateColumns={'repeat(auto-fit,minmax(200px,1fr))'}
              >
                <Test
                  current={`${dashboard.phishingEmailCount}回`}
                  title={'フィッシングメール'}
                />
                <Test
                  current={`${dashboard.sendEmailCount}通`}
                  title={'配信数'}
                />
                <Test
                  current={`${dashboard.openCount}回`}
                  hint={
                    'HTML形式でメール表示することでカウントされます。リンククリック数：URLをクリックし、フィッシングサイトに遷移することでカウントされます。'
                  }
                  title={'開封数'}
                />
                <Test
                  current={`${dashboard.accessCount}回`}
                  title={'メールリンククリック数'}
                />
                <Test
                  current={`${dashboard.loginCount}人`}
                  hint={
                    'フィッシングサイトにて、「送信」や「次へ」等のボタンをクリックすることでカウントされます。'
                  }
                  title={'リスクのあるユーザー'}
                />
                <Test
                  current={`${dashboard.answeredCount}回`}
                  hint={
                    'ラーニング画面にて、問題に回答し送信をクリックすることでカウントされます。'
                  }
                  title={'回答数'}
                />
                <Test
                  current={dashboard.correctAverageRate}
                  hint={'回答者の平均正答率となります。'}
                  title={'正答率'}
                />
              </Grid>
            )}
          </DashboardModel>
        </Box>
      </Base>
    )
  }
)
