export const ROLES = [
  {
    admin: true,
    name: 'デフォルト設定',
    role: {
      read: { name: 'isDefault', text: 'デフォルト設定' },
    },
  },
  {
    admin: false,
    name: 'ダッシュボード',
    role: {
      read: { name: 'readDashboard', text: 'ダッシュボード表示' },
    },
  },
  {
    admin: false,
    name: 'フィッシングメール管理',
    role: {
      read: { name: 'readPhishingMail', text: 'フィッシングメール表示' },
      write: { name: 'writePhishingMail', text: 'フィッシングメール編集' },
    },
  },
  {
    admin: false,
    name: 'ユーザー管理',
    role: {
      read: { name: 'readUser', text: 'ユーザー表示' },
      write: { name: 'writeUser', text: 'ユーザー編集' },
    },
  },
  {
    admin: false,
    name: '組織管理',
    role: {
      read: { name: 'readOrganization', text: '組織表示' },
      write: { name: 'writeOrganization', text: '組織編集' },
    },
  },
  {
    admin: false,
    name: 'グループ管理',
    role: {
      read: { name: 'readGroup', text: 'グループ表示' },
      write: { name: 'writeGroup', text: 'グループ編集' },
    },
  },
  {
    admin: false,
    name: '役職管理',
    role: {
      read: { name: 'readPosition', text: '役職表示' },
      write: { name: 'writePosition', text: '役職編集' },
    },
  },
  {
    admin: false,
    name: '権限管理',
    role: {
      read: { name: 'readRole', text: '権限表示' },
      write: { name: 'writeRole', text: '権限編集' },
    },
  },
  {
    admin: false,
    name: '設定管理',
    role: {
      read: { name: 'readConfig', text: '設定表示' },
      write: { name: 'writeConfig', text: '設定編集' },
    },
  },
  {
    admin: false,
    name: 'PDF管理',
    role: {
      read: { name: 'readPdf', text: 'PDF表示' },
      write: { name: 'writePdf', text: 'PDF編集' },
    },
  },
  {
    admin: true,
    name: 'フィッシングサイト管理',
    role: {
      read: { name: 'readPhishingSite', text: 'フィッシングサイト表示' },
      write: { name: 'writePhishingSite', text: 'フィッシングサイト編集' },
    },
  },
  {
    admin: true,
    name: 'ドメイン管理',
    role: {
      read: { name: 'readFromDomain', text: 'ドメイン表示' },
      write: { name: 'writeFromDomain', text: 'ドメイン編集' },
    },
  },
  {
    admin: true,
    name: '企業管理',
    role: {
      read: { name: 'readCompany', text: '企業表示' },
      write: { name: 'writeCompany', text: '企業編集' },
    },
  },
  {
    admin: true,
    name: 'メールテンプレート管理',
    role: {
      read: { name: 'readMailTemplate', text: 'メールテンプレート表示' },
      write: { name: 'writeMailTemplate', text: 'メールテンプレート編集' },
    },
  },
  {
    admin: true,
    name: '動画管理',
    role: {
      read: { name: 'readLearningMovie', text: '動画表示' },
      write: { name: 'writeLearningMovie', text: '動画編集' },
    },
  },
  {
    admin: true,
    name: 'コミック管理',
    role: {
      read: { name: 'readLearningComic', text: 'コミック表示' },
      write: { name: 'writeLearningComic', text: 'コミック編集' },
    },
  },
  {
    admin: true,
    name: '学習コンテンツ管理',
    role: {
      read: { name: 'readLearningText', text: '学習コンテンツ表示' },
      write: { name: 'writeLearningText', text: '学習コンテンツ編集' },
    },
  },
  {
    admin: false,
    name: 'アンケート管理(ユーザー)',
    role: {
      read: { name: 'readResearch', text: 'アンケート表示' },
      write: { name: 'writeResearch', text: 'アンケート編集' },
    },
  },
  {
    admin: false,
    name: '添付ファイル管理',
    role: {
      read: { name: 'readCompanyAttach', text: '添付ファイル表示' },
      write: { name: 'writeCompanyAttach', text: '添付ファイル編集' },
    },
  },
  {
    admin: true,
    name: 'IPフィルター管理',
    role: {
      read: { name: 'readIpFilter', text: 'IPフィルター表示' },
      write: { name: 'writeIpFilter', text: 'IPフィルター編集' },
    },
  },
  {
    admin: true,
    name: 'アンケート管理(管理者)',
    role: {
      read: { name: 'readAdminResearch', text: 'アンケート表示' },
      write: { name: 'writeAdminResearch', text: 'アンケート編集' },
    },
  },
  {
    admin: true,
    name: 'ドキュメント管理',
    role: {
      read: { name: 'readDocument', text: 'ドキュメント表示' },
      write: { name: 'writeDocument', text: 'ドキュメント編集' },
    },
  },
] as const
